import { css, keyframes } from "styled-components";

export const SITE_TITLE = "Plant Plan";
export const SITE_DESCRIPTION =
  "Plant Plan is the plant company installing and maintaining indoor & outdoor commercial plants & green walls for offices, restaurants & hotels UK-wide, inc. London, Birmingham & Manchester.";
export const SITE_HANDLE = "";
export const SITE_URL = "https://www.plantplan.co.uk";
export const BREAKPOINT_LG = 1179;
export const BREAKPOINT_MD = 991;
export const BREAKPOINT_SM = 767;
export const BREAKPOINT_XS = 558;
export const BREAKPOINT_XXS = 376;
export const OFFSET_SIDE = 130;
export const MOCKUP_WIDTH = 1440;
export const CONTAINER_WIDTH = 1180;
export const CONTAINER_WIDTH_LARGE = 1440;
export const OFFSET_SIDE_SM = 20;
export const OFFSET_SIDE_XSM = 20;
export const EASE = [0.76, 0, 0.24, 1];
export const EASE_IN = [0.215, 0.61, 0.355, 1];
export const CUBIC_EASE = [0.4, 1.42, 0.26, 1];
export const EASE_OUT_CUBIC = "0.46, 0.88, -0.46, 1.02";
export const CUBIC_EASE_IN = "1,0,.25,.995";
export const CUBIC_EASE_IN_FRAMER = [1, 0, 0.25, 0.995];
export const EASE_OUT_CUBIC_FRAMER = [0.46, 0.88, -0.46, 1.02];
export const NAVIGATION_BAR_HEIGHT = 64;
export const SPRING_CONFIG = {
  type: "spring",
  damping: 100,
  mass: 5,
  tension: 1050,
  friction: 20,
  duration: 0.2,
  stiffness: 450,
};
