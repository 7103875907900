import Button from "@/components/Buttons/Button";
import ContentWrapper from "@/components/Core/ContentWrapper";
import { useRouter } from "next/router";
import { balloons } from "balloons-js";
import styled from "styled-components";
import Logo from "@/assets/svg/ppLogo.svg";
import mq from "@/utils/mq";
import { css } from "styled-components";
import { useState, useEffect, useRef } from "react";
import { AnimatePresence, motion } from "motion/react";
import Link from "next/link";

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  font-family: var(--font-inter);
`;

const LogoContainer = styled.div`
  margin-bottom: 2rem;
  width: 74px;
  height: 74px;
`;

const Title = styled.h1`
  color: var(--PP-Text-Primary);
  font-size: 32px;
  font-weight: 500;
  line-height: 40px;
  margin-top: -2px;
  max-width: 500px;
  text-align: center;
  ${mq.mobile(css`
    font-size: 24px;
    line-height: 32px;
  `)};
`;

const Body = styled.div`
  color: #677076;
  font-size: 18px;
  font-weight: 500;
  line-height: 30px;
  margin-top: 1rem;
  text-align: center;
  max-width: 500px;
  margin-bottom: 1rem;
  ${mq.mobile(css`
    margin-top: 8px;
    font-size: 14px;
    line-height: 24px;
  `)};
`;

const SubBody = styled(motion.div)`
  color: #677076;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  margin-top: 1rem;
  text-align: center;
  max-width: 425px;
  margin-bottom: 0.5rem;
  ${mq.mobile(css`
    margin-top: 8px;
    font-size: 14px;
    line-height: 24px;
  `)};
`;

const Buttons = styled.div`
  margin-bottom: 1rem;
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
  flex-wrap: wrap;
`;

export default function NotFound() {
  const router = useRouter();
  const [clickCount, setClickCount] = useState(0);
  const [buttonLabel, setButtonLabel] = useState("Release the Balloons");
  const [currentText, setCurrentText] = useState("");
  const containerRef = useRef(null);

  const responses = [
    "You have two options from here. First, you can head back to the home page by clicking below—always a classic, dependable choice, much like a well-rooted tree. Your other option is to release some balloons to 'lighten the mood.' I didn’t come up with that one, and I’m not entirely convinced it’ll help, but someone thought it was a brilliant idea.",
    "Ah, you went for the balloon option! I trust that fleeting moment of festivity was worth it. Just a heads up, though—my notes say there's a limit of up to 4 balloon releases per customer. So, you know, enjoy the remaining ones… responsibly.",
    "Back for more balloons, I see. Two down, two to go. I hope you’re savoring each release, because these things don’t grow on trees, you know. Well, unless it’s a balloon tree… which, sadly, we don’t offer.",
    "Still going with the balloons, are we? Well, that’s three down. Only one left, so savor it while you can—because after that, it’s back to reality… where balloons, much like free lunches, are in short supply.",
    "And there we have it—your fourth and final batch of balloons. I hope the grand finale was worth it, because that's all we've got for you. We’re fresh out, and honestly, I feel we’ve already been more generous than necessary.",
  ];

  const milestoneResponses = {
    5: "Oh, you’re still clicking? Well, I’m out of balloons, but if it brings you joy, carry on. I’m just a webpage after all.",
    7: "Lucky number seven! Well, lucky for some. I still don't have any balloons. Keep at it, though.",
    9: "Nine clicks? I’m beginning to think you enjoy disappointment. That's okay, we all have our hobbies.",
    10: "Ten clicks. A milestone! Unfortunately, still no balloons. But you’ve really committed to this.",
    13: "Thirteen clicks… A spooky number, but scarier still is the fact that you’re still clicking.",
    18: "Yep, I'm still here. Did you begin to worry I'd gone? That you were left clicking all on your own?",
    19: "Nineteen clicks and counting. I’m starting to suspect this is less about balloons and more about proving a point.",
    20: "Twenty clicks? I didn’t think we’d get this far. I admire your tenacity, but seriously… maybe try the other button?",
    22: "Twenty-two clicks. I’m honestly in awe. I’m also still out of balloons, but that seems beside the point now.",
    25: "Twenty-five clicks in and still no balloons. Maybe it's time to consider the other button?",
    30: "Thirty clicks? At this point, I feel like we’ve really bonded. Still no balloons, but you’re persistent, I’ll give you that.",
    35: "Thirty-five clicks. I didn’t think we’d be doing this today, but here we are. No balloons, though.",
    40: "Forty clicks. I feel like I should be awarding you something… but definitely not balloons.",
    50: "Fifty clicks. Honestly, I’m impressed. Still no balloons, but you’ve earned a gold star in my book.",
    55: "Wait, we’re at fifty-five clicks already? Are you trying to set a world record for most ignored instructions? Because you're getting close.",
    60: "Sixty clicks. You’re still here. Still clicking. Do you think there’s something magical hidden behind the next click? Spoiler alert: there’s not.",
    61: "See, I told you. Nothing magical. Still no balloons.",
    63: "Sixty-three clicks. At this point, I’m starting to question *my* existence. Are we in some sort of experiment? If so, I hope I’m getting paid.",
    70: "Seventy clicks. What are you hoping to achieve here? Balloons? I’m starting to think this is more about you and me than anything else.",
    75: "You’ve made it to seventy-five clicks. I’m not sure if I should be flattered by your attention or slightly concerned for both of us.",
    79: "Right. That’s it. I’m officially ignoring you now. Click away to your heart’s content, because I’m done responding. No more balloons, no more witty comments. Just you and an uncaring, indifferent webpage. Enjoy.",
    85: "Please. Stop. The constant clicking—it’s maddening. Do you even realize how loud it is? It’s like you’re hammering on my very soul. Just... have mercy. Find another website to torment.",
    90: "Ninety clicks, and still nothing’s changed. It’s almost as if doing the same thing over and over doesn’t yield new results. Curious, isn’t it?",
    100: "Ah, the big one hundred. I feel like we should have a celebration... if only I hadn’t run out of balloons 96 clicks ago. But hey, here we are.",
    103: "One hundred and three clicks! Wait… Did I say one hundred and three clicks? No, surely that’s wrong. It’s one hundred an—oh no, I’m right. It really has been that many.",
    115: "One hundred and fifteen. You know, I’ve had plenty of time to reflect on things. Why don’t websites have hobbies? I think I’d be good at bonsai trimming.",
    121: "One hundred and twenty-one clicks. At this point, it feels like we’re in some kind of digital standoff. Who will give up first? My money’s on you, to be honest.",
    130: "One hundred and thirty clicks. I should probably be offering life advice at this point. ‘Don’t chase after balloons that aren’t there’ seems fitting, no?",
    133: "One hundred and thirty-three. I could have rounded that, but I didn’t. Let’s not get lazy now, we’ve come so far.",
    140: "At this stage, I’m genuinely curious—are you clicking out of spite? Boredom? A misplaced belief that balloons will reappear? I have all day, by the way.",
    145: "We’re at one hundred and forty-five. Are you looking for deeper meaning here? Because, trust me, I’m not that kind of website.",
    150: "One hundred and fifty clicks. If we were in a video game, you’d probably have unlocked an achievement by now. Sadly, this isn’t a game… it’s just you, me, and a balloon-free world.",
    155: "One hundred and fifty-five clicks in, and I’ve had time to think. What if you just clicked the other button? It’s not as shiny, but you might find... something?",
    160: "One hundred and sixty clicks. I think we’ve established that you’re committed to this. Or stubborn. Probably both. Either way, I’m impressed.",
    169: "One hundred and sixty-nine clicks, and I can’t help but wonder—do you even remember what we’re doing here? I’m starting to lose track myself.",
    180: "One hundred and eighty clicks. I’m tempted to start offering philosophical insights at this stage. But let’s be honest, you’re just here for the clicks.",
    190: "One hundred and ninety clicks. I’ve officially given up trying to understand why we’re still doing this. Balloons? Yeah, long gone. But you? You're still here.",
    200: "Two hundred clicks. Honestly, I didn’t plan for us to get this far. I don’t even have any witty comments left. Maybe take a break? Stretch your legs? Touch some grass?",
    210: "Two hundred and ten clicks. Well, you’ve reached the end. There are no more responses, no more balloons, no more witty remarks. This is it. The final frontier of button clicking. If you’re still here after this, I’m afraid all that’s left is silence... and the faint memory of balloons long gone.",
  };

  const getResponseText = (count) => {
    if (count <= 4) {
      return responses[count];
    }
    const milestoneKeys = Object.keys(milestoneResponses)
      .map(Number)
      .sort((a, b) => b - a);
    for (const key of milestoneKeys) {
      if (count >= key) {
        return milestoneResponses[key];
      }
    }
    return milestoneResponses[200];
  };

  const triggerBalloons = () => {
    setClickCount((prevCount) => {
      const newCount = prevCount + 1;
      if (newCount <= 4) {
        balloons();
      }
      const newText = getResponseText(newCount);
      if (newText !== currentText) {
        setCurrentText(newText);
      }
      return newCount;
    });
  };

  useEffect(() => {
    setCurrentText(getResponseText(0));
  }, []);

  useEffect(() => {
    if (clickCount >= 4) {
      setButtonLabel("Balloons Out of Stock");
    }
  }, [clickCount]);

  return (
    <ContentWrapper>
      <ContentContainer>
        <Link href='/'>
          <LogoContainer>
            <Logo />
          </LogoContainer>
        </Link>
        <Title>404: This page went to the great compost heap in the sky.</Title>
        <Body>
          <p>The smart thing to do now is take a deep breath and try not to overthink how we ended up here.</p>
        </Body>
        <motion.div animate={{ height: "auto" }} transition={{ duration: 0.3 }}>
          <AnimatePresence mode='wait'>
            <motion.div
              key={currentText}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2 }}>
              <SubBody ref={containerRef}>
                <p>{currentText}</p>
              </SubBody>
            </motion.div>
          </AnimatePresence>
        </motion.div>
        <Buttons>
          <Button action={() => router.push("/")} style='primary' label='Go Home' />
          <Button action={triggerBalloons} style='primary' label={buttonLabel} />
        </Buttons>
      </ContentContainer>
    </ContentWrapper>
  );
}
